<template>
  <div class="header-no-user">
    <router-link class="signup-btn button" to="/signup" v-if="!hideRegisterBtn">{{ $locale["input-signup-btn"] }}</router-link>
    <router-link class="login-btn primary-button button" to="/login">
      <div class="label">{{ $locale["login-btn"] }}</div>
      <figure class="icon"><iconic name="user_circle" /></figure>
    </router-link>
    <LanguageButton _for="headerLanding" active="button" />
  </div>
</template>

<script>
export default {
  computed: {
    hideRegisterBtn: function() {
      return this.$lang === "es" && this.$screen.width < 420;
    },
  },
};
</script>

<style lang="scss">
.header-no-user {
  @include Flex(inherit, center, center);
  margin: 0 $mpadding/2;
  gap: $mpadding/2;
  .login-btn {
    @include Flex(inherit, center, center);
    padding: $mpadding/2.5 $mpadding/2;
    .label {
      white-space: nowrap;
    }
    .icon {
      margin: 0 0 0 $mpadding/2;
      font-size: 120%;
    }
  }
  .signup-btn {
    padding: $mpadding/2.5 $mpadding/2;
    &:active {
      @include d-active;
    }
  }
  .button {
    margin: 0;
    font-size: 90%;
  }
  .headerLanding {
    margin: 0 $mpadding/2;
    .language-button-flag {
      width: $mpadding * 2.2;
      height: $mpadding * 2.2;
      display: block;
      border: solid 2px $primary_color;
    }
    .language-button-text {
      display: none;
    }
  }
  @media (min-width: $tablet_width) {
    gap: 0;
    .login-btn {
      padding: $mpadding/2.5 $mpadding;
    }
    .signup-btn {
      padding: $mpadding/2.5 $mpadding;
    }
    .button {
      margin: 0 $mpadding/2;
    }
  }
}
</style>
