<template>
  <nav class="header-nav" v-if="locale">
    <router-link class="header-nav-button" to="/">{{ locale["object"]["nav"]["home-btn"] }}</router-link>
    <router-link class="header-nav-button" :to="`/watch`">
      {{ $locale["watch"] }}
    </router-link>
    <a class="header-nav-button pointer" @click="setQuery({ messages: 'wall' })">
      <span>{{ locale["object"]["nav"]["messages-btn"] }}</span>
      <BubbleNumber v-if="messagesUnread" :number="messagesUnread" />
    </a>
    <router-link class="header-nav-button" :to="`${$isCreator ? `/${$user.user}` : $links['my-profile']}`">
      {{ locale["object"]["nav"]["profile-btn"] }}
    </router-link>
    <a
      @click="goFilter({ view: 'others' }, $links['my-notifications'])"
      relative
      :class="`header-nav-button ${mkLink($links['my-notifications'], 'router-link-exact-active')}`"
    >
      {{ locale["object"]["nav"]["notifications-btn"] }}
      <BubbleNumber v-if="notificationsUnread" :number="$notifications" />
    </a>
  </nav>
</template>

<script>
export default {
  props: ["locale"],
  computed: {
    messagesUnread: function() {
      return this.$store.state.counter.messagesUnread;
    },
    notificationsUnread: function() {
      return this.$store.state.counter.notificationsUnread;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  display: none;
  &-button {
    padding: $mpadding/2;
    cursor: pointer;
    position: relative;
    &.router-link-exact-active {
      color: $primary_color;
      font-weight: bold;
    }
    &:hover {
      color: $primary_color;
    }
  }

  @media (min-width: $tablet_width) {
    display: flex;
  }

  @media (min-width: $desktop_width) {
    margin: 0 $mpadding;
    &-button {
      padding: $mpadding;
    }
  }
}
</style>
