<template>
  <header :class="`main-header ${headerClass} ${$user ? '' : '--public'}`" sticky>
    <div class="main-header-container" v-if="$locale">
      <div class="main-header-container-options">
        <span class="main-header-title nav-button" @click="navigateToHome" cursor="pointer" active="button">
          <figure class="logo display-flex">
            <iconic name="mf_logo_shadow" :options="{ original: true }" />
          </figure>
        </span>
      </div>
      <div class="main-header-container-right">
        <HeaderNav v-if="$user" :locale="$locale" />
        <DesktopSearchHeader v-if="$user && $screen.width > 768" :locale="$locale" />
        <div class="main-header-container-user" v-if="$user && isVisible('user')">
          <UserCreditsBtn v-if="$profile" />
          <div class="name" ellipsis>
            <span>{{ $user.name }}</span>
          </div>
          <div v-gap="['calc(var(--mpadding)/4)', 'calc(var(--mpadding))', 'calc(var(--mpadding)/4)', 'calc(var(--mpadding)/2)']">
            <UserAvatar
              v-if="$user && $profile"
              :user="Go.extend($user, $profile.user)"
              :size="sizeAvatar"
              @click="UserAdminMenu()"
              dshadow
              active="button"
              cursor="pointer"
            />
          </div>
        </div>
        <div class="main-header-container-user" v-else-if="isVisible('user')">
          <HeaderUserNoSesion />
        </div>
        <div class="main-header-container-user" v-else>
          <slot name="user" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import UserCreditsBtn from "../user/UserCreditsBtn.vue";
import HeaderNav from "./HeaderNav.vue";
import DesktopSearchHeader from "../search/DesktopSearchHeader.vue";
import HamburgerBtn from "./HamburgerBtn.vue";
import HeaderUserNoSesion from "./HeaderUserNoSesion.vue";
export default {
  components: { HeaderNav, HamburgerBtn, UserCreditsBtn, DesktopSearchHeader, HeaderUserNoSesion },
  props: { options: Object },
  methods: {
    isVisible: function(feature) {
      if (this.options?.remove?.includes(feature)) {
        return false;
      }
      return true;
    },
  },
  computed: {
    sizeAvatar: function() {
      return this.$screen.width > 768 ? "40px" : "36px";
    },
    headerClass: function() {
      return this.$store.state.headerClass;
    },
  },
};
</script>

<style lang="scss">
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: $header_height;
  background: #fff;
  box-shadow: 0 0 7.5px $alto;
  & + [class*="-feed"],
  & + [class*="landing"],
  & + [class*="page-container"] {
    margin-top: $header_height;
  }
  &-title {
    font-size: 120%;
    white-space: nowrap;
    .logo {
      margin: 0 0 0 $mpadding;
      color: $primary_color;
      .iconic {
        @include Flex(inherit, center, center);
      }
      .iconic svg {
        width: 88px;
        height: auto;
      }
      .iconic svg,
      .iconic img {
        max-height: calc(#{$header_height} - #{$mpadding/2});
      }
    }
  }
  &-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    &-right,
    &-user {
      display: flex;
      align-items: center;
    }
    &-user {
      .user-credits-btn {
        padding: 0 $mpadding;
      }
      .avatar {
        display: table;
        figure {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: $primary_color;
          color: white;
          border-radius: 50%;
          margin: $mpadding/2 $mpadding;
          cursor: pointer;
          user-select: none;
          background-size: cover;
          box-shadow: $dshadow;
        }
        &:active {
          opacity: 0.8;
        }
      }
      .name {
        margin-left: $mpadding;
        max-width: 140px;
        display: none;
      }
      &-button {
        padding: 0 $mpadding;
        color: $primary_color;
        cursor: pointer;
        height: $header_height;
        display: flex;
        align-items: center;
        .iconic {
          font-size: 120%;
        }
        &:active {
          opacity: 0.8;
        }
      }
    }
  }
  &.--public {
    .logo {
      .iconic svg,
      .iconic img {
        max-width: 72px;
      }
    }
  }
  @media (min-width: $mobile_m_width) {
    &.--public {
      .logo {
        .iconic svg,
        .iconic img {
          max-width: fit-content;
        }
      }
    }
  }
  @media (min-width: $pocket_width) {
    &.--public {
      .logo {
        .iconic svg,
        .iconic img {
          max-width: fit-content;
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    &-container {
      &-user {
        .avatar {
          figure {
            width: 38px;
            height: 38px;
            line-height: 38px;
            margin: $mpadding/2 $mpadding;
          }
        }
      }
    }
  }
  @media (min-width: $content_width) {
    &-container {
      &-user {
        .name {
          display: block;
        }
      }
    }
  }
}
</style>
