<template>
  <div :class="`header-search ${searching ? 'searching' : ''}`" :id="`search-${id}`" v-if="$locale">
    <div class="header-search-overlay" @click="searchLeave"></div>
    <div class="header-search-content">
      <input
        type="text"
        name="search"
        :placeholder="$locale['object']['nav']['search-label']"
        autocomplete="off"
        @keyup="onSearch"
        @focus="searchEnter"
        :value="word"
      />
      <button><iconic name="search" /></button>
      <div class="header-search-result">
        <PreloadingLine v-if="loading" />
        <div class="content" :placeholder="$locale['object']['nav']['search-suggest']">
          <div class="content-list" v-if="creators && creators.length">
            <router-link :to="`/${creator.user}`" v-for="(creator, index) in creators" :key="`cr-${index}`" flex w100>
              <div :class="`content-list-item`" @click="searchLeave" w100>
                <div class="wrap-picture">
                  <figure class="picture" :style="`--avatar:url(${creator.picture});`"></figure>
                  <!-- <figure class="flag" :style="`--flag:url(${creator.country_flag});`"></figure> -->
                </div>
                <div class="info">
                  <p class="name">{{ creator.name }}</p>
                  <p class="user">@{{ creator.user }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreloadingLine from "../widgets/PreloadingLine.vue";
export default {
  components: { PreloadingLine },
  data: function() {
    return {
      id: this.$global.fastID(),
      loading: false,
      creators: null,
      word: null,
      timeout: null,
      searching: false,
    };
  },
  methods: {
    searchLeave: function() {
      this.searching = false;
    },
    searchEnter: function() {
      this.searching = true;
    },
    getData: async function({ string }) {
      this.loading = true;

      const callback = ({ creators }) => {
        this.loading = false;
        this.creators = creators;
      };

      const error = (error) => {
        this.loading = false;
        this.ialert.go({ title: "error", message: error });
      };

      this.$store.dispatch("searchCreator", { string, callback, error });
    },
    onSearch: function({ target }) {
      this.word = target.value;
      !this.loading && (this.loading = true);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getData({ string: target.value });
      }, 500);
    },
  },
  mounted: function() {
    this.word = this.dataSearch?.word;
    if (this.word) {
      this.creators = this.dataSearch?.result;
    }
  },
  computed: {
    dataSearch: function() {
      return this.$store.state.search;
    },
  },
};
</script>

<style lang="scss">
$avatar_size: 42px;
.header-search {
  display: none;
  &-content {
    position: relative;
    width: 100%;
    border-radius: 2pc;
    border: solid 1px $alto;
    z-index: 1;
    input {
      padding: $mpadding/2 $mpadding * 2.5 $mpadding/2 $mpadding;
      font-size: 100%;
      font-family: $primary_font;
      width: 100%;
      border: none;
      background: none;
      border-radius: 2pc;
    }
    button {
      position: absolute;
      padding: $mpadding/2 $mpadding $mpadding/2 $mpadding/2;
      right: 0;
      background: none;
      border-radius: 0 2pc 2pc 0;
      border: none;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      font-size: 110%;
      color: $sec_color;
    }
  }
  &-result {
    display: none;
    position: absolute;
    background-color: #fff;
    width: calc(100% + 2px);
    left: -1px;
    border: solid 1px $alto;
    border-radius: 0 0 $mpadding $mpadding;
    overflow: hidden;
    .content {
      &:empty::before {
        content: attr(placeholder);
        opacity: 0.7;
        pointer-events: none;
        padding: $mpadding/2 $mpadding;
        display: block;
      }
      &-list {
        &-item {
          display: flex;
          align-items: center;
          padding: $mpadding/2 $mpadding;
          &:not(:last-child) {
            margin: 0 0 $mpadding/2 0;
          }
          &:hover {
            background-color: #e9e9e9;
          }
          .wrap-picture {
            position: relative;
            .picture {
              @include BgImage();
              width: $avatar_size;
              height: $avatar_size;
              background-image: var(--avatar);
              border-radius: 50%;
            }
            .flag {
              position: absolute;
              width: 20px;
              height: 20px;
              right: -$mpadding/1.5;
              bottom: 0;
              background-image: var(--flag);
              border-radius: 50%;
              background-size: cover;
            }
          }
          .info {
            margin: 0 0 0 $mpadding/1.5;
            .name {
              font-weight: bold;
            }
            .user {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  &-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.searching &-content {
    border-radius: $mpadding $mpadding 0 0;
  }
  &.searching &-result {
    display: block;
  }
  &.searching &-overlay {
    display: block;
  }
  @media (min-width: $tablet_width) {
    display: flex;
  }
  @media (min-width: $desktop_width) {
    display: flex;
    width: 280px;
  }
}
</style>
